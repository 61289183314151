<template>
  <b-card>
    <div class="mb-5">
      <b-card-title
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="SettingsIcon"
          size="21"
          class="mr-1"
        />
        Основные
      </b-card-title>
      <div class="row">
        <div class="col-12 col-md-4">
          <b-form-group
            label="Название компании"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="editSite.name"
              v-validate="'required'"
              name="name"
              placeholder="Название компании"
            />
            <small
              v-show="errors.has('name')"
              class="text-danger"
            >{{ errors.first('name') }}</small>
          </b-form-group>
        </div>
        <div class="col-12 col-md-4">
          <b-form-group
            label="Website"
            label-for="domain"
          >
            <b-form-input
              id="domain"
              v-model="editSite.domain"
              v-validate="'required'"
              name="domain"
              placeholder="domain.com"
            />
            <small
              v-show="errors.has('domain')"
              class="text-danger"
            >{{ errors.first('domain') }}</small>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <b-form-group
            label="Корень дерева страниц сайта"
            label-for="url.slug_prefix"
          >
            <b-form-input
              id="url.slug_prefix"
              v-model="editSite.settings['url.slug_prefix']"
              v-validate="'required'"
              name="url.slug_prefix"
              placeholder="/"
            />
            <small
              v-show="errors.has('url.slug_prefix')"
              class="text-danger"
            >{{ errors.first('url.slug_prefix') }}</small>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group>
            <label class="d-block">
              Логотип сайта Шапка
              <feather-icon
                v-b-tooltip.hover.top="'Формат svg, размер: 200×35'"
                icon="HelpCircleIcon"
                size="14"
              />
            </label>
            <input-file
              v-model="editSite.settings['content.logo_header']"
            />
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <b-form-group>
            <label class="d-block">
              Логотип сайта Мобильная версия
              <feather-icon
                v-b-tooltip.hover.top="'Формат svg, размер: 200×35'"
                icon="HelpCircleIcon"
                size="14"
              />
            </label>
            <input-file
              v-model="editSite.settings['content.logo_header_mobile']"
            />
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <b-form-group>
            <label class="d-block">
              Логотип сайта Футер
              <feather-icon
                v-b-tooltip.hover.top="'Формат svg, размер: 200×35'"
                icon="HelpCircleIcon"
                size="14"
              />
            </label>
            <input-file
              v-model="editSite.settings['content.logo_footer']"
            />
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <b-form-group>
            <label class="d-block">
              Фавикон
              <feather-icon
                v-b-tooltip.hover.top="'Формат svg, размер: 120×120'"
                icon="HelpCircleIcon"
                size="14"
              />
            </label>
            <input-file
              v-model="editSite.settings['content.favicon']"
              size="sm"
              :loading="editSite.settings['content.favicon.in_process'] && (editSite.settings['content.favicon'].length === 0 || editSite.settings['content.favicon'] === null)"
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <b-card-title
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="ZapIcon"
          size="21"
          class="mr-1"
        />
        Индексация сайта
      </b-card-title>
      <div class="row">
        <div class="col-12 col-md-8">
          <b-form-group>
            <b-form-checkbox
              id="indexing.enabled"
              v-model="switchIndexChecked"
              name="indexing.enabled"
              switch
            >
              <span>Скрыть сайт от индексации (noindex)</span><br>

              <span class="text-muted">
                при скрытии сайта из индексации на все страницы добавится тег &lt;meta name=&quot;robots&quot; content=&quot;noindex&quot;&gt;
              </span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              id="seo.last_modified_header"
              v-model="editSite.settings['seo.last_modified_header']"
              name="seo.last_modified_header"
              switch
            >
              <span>Использовать заголовок Last-Modified</span><br>

              <span class="text-muted">
                Добавляет на все страницы HTTP заголовок Last-Modified содержащий время последнего изменения страницы
              </span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            label="Ключ Yandex Index Now"
            label-for="indexing.yandex_now_key"
          >
            <b-form-input
              id="indexing.yandex_now_key"
              v-model="editSite.settings['indexing.yandex_now_key']"
              name="indexing.yandex_now_key"
              placeholder=""
            />
            <small
              v-show="errors.has('indexing.yandex_now_key')"
              class="text-danger"
            >{{ errors.first('indexing.yandex_now_key') }}</small>
          </b-form-group>
          <b-form-group
            label="Robots.txt"
            label-for="indexing.robots_txt"
          >
            <b-form-textarea
              id="indexing.robots_txt"
              v-model="editSite.settings['indexing.robots_txt']"
              name="indexing.robots_txt"
              rows="12"
              no-resize
              class="text-monospace"
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <b-card-title
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="MapPinIcon"
          size="21"
          class="mr-1"
        />
        Контакты
      </b-card-title>
      <div class="row">
        <div class="col-12 col-md-4 col-xl-4">
          <b-form-group
            label="Адрес"
            label-for="contacts.address"
          >
            <b-form-input
              id="contacts.address"
              v-model="editSite.settings['contacts.address']"
              v-validate="'required'"
              name="contacts.address"
            />
            <small
              v-show="errors.has('contacts.address')"
              class="text-danger"
            >{{ errors.first('contacts.address') }}</small>
          </b-form-group>
        </div>
        <div class="col-12 col-md-4 col-xl-4">
          <b-form-group
            label="Город"
            label-for="contacts.city"
          >
            <b-form-input
              id="contacts.city"
              v-model="editSite.settings['contacts.city']"
              v-validate="'required'"
              name="contacts.city"
            />
            <small
              v-show="errors.has('contacts.city')"
              class="text-danger"
            >{{ errors.first('contacts.city') }}</small>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8 col-xl-4">
          <b-form-group
            label="Телефон"
            label-for="contacts.phone"
          >
            <b-form-input
              id="contacts.phone"
              v-model="editSite.settings['contacts.phone']"
              v-validate="'required'"
              name="contacts.phone"
            />
            <small
              v-show="errors.has('contacts.phone')"
              class="text-danger"
            >{{ errors.first('contacts.phone') }}</small>
          </b-form-group>
        </div>
        <div class="col-12 col-md-8 col-xl-4">
          <b-form-group
            label="Часы работы"
            label-for="contacts.working_hours_struct"
          >
            <InputTime
              id="contacts.working_hours_struct"
              :key="editSite.settings['contacts.working_hours_text']"
              v-model="editSite.settings['contacts.working_hours_struct']"
              name="contacts.working_hours_struct"
              :text="editSite.settings['contacts.working_hours_text']"
            />
            <small
              v-show="errors.has('contacts.working_hours_struct')"
              class="text-danger"
            >{{ errors.first('contacts.working_hours_struct') }}</small>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <b-card-title
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="LayoutIcon"
          size="21"
          class="mr-1"
        />
        Заголовки и мета теги служебных страниц
      </b-card-title>
      <SettingsStatic />
    </div>
    <div class="mb-5">
      <b-card-title
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="LockIcon"
          size="21"
          class="mr-1"
        />
        Yandex SmartCaptcha
      </b-card-title>
      <div class="row">
        <div class="col-12 col-md-4">
          <b-form-group
            label="Ключ клиента"
            label-for="requests.yandex_captcha_token_client"
          >
            <b-form-input
              id="requests.yandex_captcha_token_client"
              v-model="editSite.settings['requests.yandex_captcha_token_client']"
              name="requests.yandex_captcha_token_client"
            />
            <small
              v-show="errors.has('requests.yandex_captcha_token_client')"
              class="text-danger"
            >{{ errors.first('requests.yandex_captcha_token_client') }}</small>
          </b-form-group>
        </div>
        <div class="col-12 col-md-4">
          <b-form-group
            label="Ключ сервера"
            label-for="requests.yandex_captcha_token_server"
          >
            <b-form-input
              id="requests.yandex_captcha_token_server"
              v-model="editSite.settings['requests.yandex_captcha_token_server']"
              name="requests.yandex_captcha_token_server"
            />
            <small
              v-show="errors.has('requests.yandex_captcha_token_server')"
              class="text-danger"
            >{{ errors.first('requests.yandex_captcha_token_server') }}</small>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <b-card-title
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="MailIcon"
          size="21"
          class="mr-1"
        />
        Почта
      </b-card-title>
      <div class="row">
        <div class="col-12 col-md-8">
          <b-form-group
            label="Список получателей заявок"
            label-for="requests.email_list"
          >
            <select-tags
              id="requests.email_list"
              v-model="editSite.settings['requests.email_list']"
              name="requests.email_list"
              :emails="true"
            />
            <small
              v-show="errors.has('requests.email_list')"
              class="text-danger"
            >{{ errors.first('requests.email_list') }}</small>
          </b-form-group>
        </div>
        <div class="col-12 col-md-8">
          <b-form-group
            label="Тема письма"
            label-for="mail.header"
          >
            <b-form-textarea
              id="mail.header"
              v-model="editSite.settings['mail.header']"
              name="mail.header"
              rows="3"
              no-resize
              class="text-monospace"
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <b-card-title
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="SendIcon"
          size="21"
          class="mr-1"
        />
        Телеграм
      </b-card-title>
      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group
            label="Cсылка на телеграм бота:"
            label-for="telegram_add_recipient_link"
          >
            <a
              :href="editSite.telegram_add_recipient_link"
              target="_blank"
            >
              {{ editSite.telegram_add_recipient_link }}
            </a>
          </b-form-group>

          <b-form-group
            label="Список получателей заявок"
            label-for="requests.telegram_list"
          >
            <div class="list-group">
              <div
                v-for="(item, key) in editSite.settings['requests.telegram_list']"
                :key="key"
                class="list-group-item d-flex align-items-center justify-content-between"
              >
                {{ item }}
                <b-button
                  v-b-tooltip.hover.top="'Удалить получателя'"
                  size="sm"
                  variant="link"
                  class="btn-icon"
                  @click.stop="removeTg(key)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="18"
                  />
                </b-button>
              </div>
            </div>
            <small
              v-show="errors.has('requests.telegram_list')"
              class="text-danger"
            >{{ errors.first('requests.telegram_list') }}</small>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <b-card-title
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="StarIcon"
          size="21"
          class="mr-1"
        />
        Отзывы
      </b-card-title>
      <div class="row">
        <div class="col-12 col-md-4">
          <b-form-group
            label="Ссылка на карточку яндекс"
            label-for="reviews.yandex.link"
          >
            <b-form-input
              id="reviews.yandex.link"
              v-model="editSite.settings['reviews.yandex.link']"
              name="reviews.yandex.link"
              placeholder=""
            />
          </b-form-group>
        </div>
        <div class="col-12 col-md-4">
          <b-form-group
            label="Рейтинг в карточке яндекса"
            label-for="reviews.yandex.rating"
          >
            <StarRating
              v-model="editSite.settings['reviews.yandex.rating']"
              :increment="0.1"
              :star-size="30"
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <b-card-title
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="CodeIcon"
          size="21"
          class="mr-1"
        />
        Скрипты
      </b-card-title>
      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group
            label="<head>"
            label-for="scripts.head"
          >
            <b-form-textarea
              id="scripts.head"
              v-model="editSite.settings['scripts.head']"
              class="text-monospace"
              rows="12"
              no-resize
              name="scripts.head"
            />
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <b-form-group
            label="<body>"
            label-for="scripts.body"
          >
            <b-form-textarea
              id="scripts.body"
              v-model="editSite.settings['scripts.body']"
              class="text-monospace"
              rows="12"
              no-resize
              name="scripts.body"
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <b-card-title
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="PieChartIcon"
          size="21"
          class="mr-1"
        />
        Аналитика
      </b-card-title>
      <div class="row">
        <div class="col-12 col-md-4">
          <b-form-group
            label="ID счетчика Я.Метрика"
            label-for="analytics.yandex.counter_id"
          >
            <b-form-input
              id="analytics.yandex.counter_id"
              v-model="editSite.settings['analytics.yandex.counter_id']"
              name="analytics.yandex.counter_id"
              placeholder="12345678"
            />
          </b-form-group>
        </div>
        <div class="col-12 col-md-4">
          <b-form-group
            label="ID цели в Я.Метрика"
            label-for="analytics.yandex.target_id"
          >
            <b-form-input
              id="analytics.yandex.target_id"
              v-model="editSite.settings['analytics.yandex.target_id']"
              name="analytics.yandex.target_id"
              placeholder="123456789"
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <b-card-title
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="HomeIcon"
          size="21"
          class="mr-1"
        />
        Хлебные крошки
      </b-card-title>
      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group
            label="Название главной страницы в хлебных крошках"
            label-for="breadcrumbs.main_page_name"
          >
            <b-form-input
              id="breadcrumbs.main_page_name"
              v-model="editSite.settings['breadcrumbs.main_page_name']"
              v-validate="'required'"
              name="breadcrumbs.main_page_name"
            />
            <small
              v-show="errors.has('breadcrumbs.main_page_name')"
              class="text-danger"
            >{{ errors.first('breadcrumbs.main_page_name') }}</small>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model="editSite.settings['breadcrumbs.show_last_item']"
            >
              Показывать название текущей страницы в хлебных крошках
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="bottom-panel">
      <div class="d-flex">
        <b-button
          class="mr-1"
          variant="primary"
          @click.prevent="onSave"
        >
          Сохранить
        </b-button>
        <b-button
          variant="outline-primary"
          @click.prevent="onCancel"
        >
          Отменить изменения
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea, BButton, VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import StarRating from 'vue-star-rating'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SelectTags from '@core/components/input/SelectTags.vue'
import InputFile from '@core/components/input/InputFile.vue'
import InputTime from '@core/components/input/InputTime.vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import Site from '../../api/http/models/site/site/Site'
import SettingsStatic from './SettingsStatic.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BButton,
    InputFile,
    InputTime,
    SelectTags,
    SettingsStatic,
    StarRating,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [mixinErrorsParse],

  data() {
    return {
      site: {
        settings: {},
      },
      editSite: {
        settings: {
          indexing: {
            yandex_now_key: null,
          },
        },
      },
      deletedTg: [],
    }
  },

  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    switchIndexChecked: {
      get() {
        return !this.editSite.settings['indexing.enabled']
      },
      set(newVal) {
        this.editSite.settings['indexing.enabled'] = !newVal
      },
    },
  },

  created() {
    this.getSettings()
  },

  methods: {
    async getSettings() {
      const response = await Site.getOne(this.currentSite.slug)
      if (response.data) {
        this.site = response.data
        this.editSite = JSON.parse(JSON.stringify(this.site))
        if (this.site.settings['content.logo_header']) {
          this.editSite.settings['content.logo_header'] = this.site.settings['content.logo_header'].key
        }
        if (this.site.settings['content.logo_header_mobile']) {
          this.editSite.settings['content.logo_header_mobile'] = this.site.settings['content.logo_header_mobile'].key
        }
        if (this.site.settings['content.logo_footer']) {
          this.editSite.settings['content.logo_footer'] = this.site.settings['content.logo_footer'].key
        }
        if (this.site.settings['content.favicon']) {
          this.editSite.settings['content.favicon'] = this.site.settings['content.favicon'].svg120.key
        }
      }
      if (this.editSite.settings['content.favicon.in_process']) {
        const timer = setInterval(() => {
          this.getSettings()
          if (this.editSite.settings['content.favicon'].length > 0) clearInterval(timer)
        }, 4000)
        if (this.editSite.settings['content.favicon'].length > 0) clearInterval(timer)
      }
    },
    async onSave() {
      const validate = await this.$validator.validateAll()
      if (validate) {
        const responseSite = await Site.update(this.currentSite.slug, this.editSite)
        const responseSettings = await Site.updateSettings(this.currentSite.slug, this.editSite.settings)
        if (this.editSite.settings['content.favicon'] !== this.site.settings['content.favicon'].svg120) {
          if (this.editSite.settings['content.favicon']) {
            await Site.generateFavicon(this.currentSite.slug, {
              favicon: this.editSite.settings['content.favicon'],
            })
          } else await Site.deleteFavicon(this.currentSite.slug)
        }
        if (this.deletedTg.length) {
          const responseTg = await Site.deleteTg(this.currentSite.slug, this.deletedTg)
          if (responseTg.errors) this.mixinErrorsParse(responseTg.errors)
        }
        if (responseSite.status === 'success' && responseSettings.status === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Настройки сохранены',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.getSettings()
        } else if (responseSite.errors || responseSettings.errors) {
          this.mixinErrorsParse(responseSite.errors)
          this.mixinErrorsParse(responseSettings.errors)
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Некоторые поля не заполнены или заполнены неверно',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    onCancel() {
      this.editSite = JSON.parse(JSON.stringify(this.site))
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Изменения отменены',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    removeTg(key) {
      this.deletedTg.push(key)
      const tgList = Object.entries(this.editSite.settings['requests.telegram_list']).filter(i => i[0] !== key)
      const tgListNew = {}
      // eslint-disable-next-line no-return-assign
      tgList.forEach(([id, value]) => tgListNew[id] = value)
      this.editSite.settings['requests.telegram_list'] = tgListNew
    },
  },
}
</script>

<style lang="scss">
  .text-monospace {
    font-family: Consolas, "courier new", monospace !important;
  }
</style>
